<template>
  <div class="sale-view">
    <div>
      <v-card flat>
        <ReportViewBar
          :title="`Sale By Salesman`"
          :start="start"
          :end="end"
        />

       <v-card-text>
         <table class="center">
            <tbody class="report-container">
              <v-card-text>
                <SaleBySalesman
                  :start="$route.query.start"
                  :end="$route.query.end"
                />
              </v-card-text>
            </tbody>
          </table>
       </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import ReportViewBar from "@/components/ReportViewBar";
// import CategoryService from "@/modules/Category/service";
import { mapGetters, mapActions } from "vuex";
const SaleBySalesman = () => import("@/modules/ReportView/GlobalReport/SaleBySalesman.vue");

// import Hashids from "hashids";
// const hashids = new Hashids();

export default {
  data() {
    return {
      start: null,
      end: null
    }
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
    totalProfit() {
      return this.totalPrice - this.totalCost;
    },
    total() {
      let total = 0;
      this.Product.map((row) => {
        total += row.totalPrice;
      });
      return total;
    },
  },
  created() {
    this.start = this.$route.query.start
    this.end = this.$route.query.end
  },
  components: { ReportViewBar, SaleBySalesman },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
  .no-print {
    display: none;
  }
  .detailBox {
    overflow: visible;
    height: 100%;
  }
  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}
.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}
.center {
  background-color: white;
  margin: auto;
  width: 100%;
}
/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
